import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            text: 'HOME',
            link: '/',
            class: 'no-hover-effect'
        },
        {
            text: 'ABOUT DR. JOSEPH',
            link: '/about/',
            class: 'no-hover-effect'
        },
        {
            text: ' SOCIAL INITIATIVES',
            link: '/social/',
            class: 'no-hover-effect'
        },
        {
            text: 'COVID 19',
            link: '/covid-19/',
            class: 'no-hover-effect'
        },
        {
            text: ' MEDIA',
            link: '/media/',
            class: 'no-hover-effect'
        },
        {
            text: 'CONTACT',
            link: '/contact/',
            class: 'no-hover-effect'
        }
    ];

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
