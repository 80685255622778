<template>
    <footer class="">
        <div class="py-5 top-footer-cont">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3">
                        <h3 class="text-head">ABOUT</h3>
                        <h4 class="mb-0 text-details">DR JOSEPH THOMAS</h4>

                        <h6 class="text-details mb-3 mb-lg-0 text-uppercase">
                            aesthetic Physician <br>
                            Body Positive Enthusiast <br>
                            Entrepreneur <br>
                            Philanthropist
                        </h6>
                    </div>
                    <div class="col-lg-3">
                        <h3 class="text-head">ADDRESS</h3>
                        <p>
                            Pallichira Medlounges Healthcare Pvt. Ltd, Perumthuruthy PO , Ezhinijillam, (Opposite HP
                            Petrol Pump) Thiruvalla, Pathanamthitta,
                            Kerala Pin 689107
                        </p>
                        <p>Email ID: <br>
                            <a href="mailto:drjosephthomas@medlounges.com">drjosephthomas@medlounges.com</a>
                        </p>
                    </div>
                    <div class="col-lg-3 mb-3 mb-lg-0">
                        <h3 class="text-head">OVERVIEW</h3>
                        <ul class="list">
                            <li>
                                <router-link to="/"> Home</router-link>
                            </li>
                            <li>
                                <router-link to="/about/"> About Dr. JOSEPH</router-link>
                            </li>
<!--                            <li>-->
<!--                                <router-link to="#"> Media</router-link>-->
<!--                            </li>-->
                            <li>
                                <router-link to="/contact/"> Contact Us</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-3">
                        <h3 class="text-head">INITIATIVES</h3>
                        <ul class="list">
                            <li>
                                <router-link to="#">Social Initiative</router-link>
                            </li>
                            <li>
                                <router-link to="#">Covid 19</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="bg-dark p-3 bottom-footer-cont">
            <div class="container">
                <div class="row">
                    <div class="col bottom-footer">
                        <p class="mb-lg-0">© {{ new Date().getFullYear() }} Dr. Joseph Thomas. All rights reserved</p>
                        <div>
                            <ul class="list-unstyled social-icons">
                                <li>
                                    <a href="https://www.facebook.com/Doctorjosephthomas/?ref=pages_you_manage"
                                       target="_blank" title="Facebook">
                                        <i class="fa fa-facebook"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/drjoseph_thomas" target="_blank"
                                       title="Instagram">
                                        <i class="fa fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/drjoseph_thomas" target="_blank" title="Twitter">
                                        <i class="fa fa-twitter"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Top-Footer'
};
</script>

<style scoped lang="scss">

.top-footer-cont {
    background-color: #262626;
    color: #888888;

    .text-head {
        font-weight: bold !important;
        font-size: 13px;
        font-family: 'Montserrat', sans-serif;
        color: #ffffff;
    }

    .text-details {
        font-weight: bold !important;
        font-family: 'Montserrat', sans-serif;
        color: #ffffff;
    }

    .list {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            position: relative;
            padding: 10px 0;
            border-bottom: 1px solid #333333;
            transition: 0.3s;

            &:hover {
                color: #cccccc;

                &:after {
                    color: #cccccc;
                }
            }

            &:after {
                font-family: "FontAwesome";
                content: "\f105";
                position: absolute;
                top: 10px;
                right: 0;
                font-size: 14px;
                color: #999999;
            }
        }
    }

}

a {
    text-decoration: none;
}

a:hover {
    color: #cccccc;
}

.bottom-footer-cont {
    background-color: #1b1b1b;
    color: #777777;

    .bottom-footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .social-icons {
            li {
                display: inline-block;
                font-size: 1.4rem;

                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
    }

    @media (min-width: 992px) {
        .bottom-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

</style>
