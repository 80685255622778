<template>
    <div class="bg-2" id="top">
        <div class="nav-cont-c py-2 py-lg-3">
            <lego-nav-bar drop-down-animation="fade" drop-down-animation-mobile="fade" shadow="0" class="container"
                          logo-text="Dr Joseph Thomas" :items="menu">
                <template #logo>
                    <router-link to="/" class="d-flex">
                        <!--                        <img src="../assets/img/logo-light.png" alt="#" class="logo-img logo-img-light mr-lg-5">-->
                        <!--                        <img src="../assets/img/logo-dark.png" alt="#" class="logo-img logo-img-dark mr-lg-5">-->
                        <img src="../assets/img/logo-dark.png" alt="#" class="logo-img mr-lg-5">
                    </router-link>
                </template>
            </lego-nav-bar>
        </div>
        <div class="pt-body">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>

        <TopFooter/>

    </div>

</template>

<script>
import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';
import TopFooter from '../views/components/Footer';

export default {
    components: {
        TopFooter,
        LegoNavBar
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

@media (min-width: 992px) {
    .pt-body {
        padding-top: 87px;
    }
}

</style>
